import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/business/personPoints/pageList", formData);
}

function exportXls(formData) {
  //导出xls
  return request.post(constant.serverUrl + "/business/personPoints/exportXls", formData);
}

export default {
  pageList,exportXls
}