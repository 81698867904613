<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/personPoints">用户积分统计表</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="学校" prop="companyId">
        <el-select-tree
          size="mini"
          :props="props"
          :options="companyResult"
          v-model="queryModel.companyId"
          height="200"
        ></el-select-tree
        >&nbsp;
        <el-checkbox v-model="queryModel.subordinate"
          >是否包含下级单位</el-checkbox
        >
      </el-form-item>
      <el-form-item label="编号" prop="personId">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.personId"
        ></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="personName">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.personName"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
     <el-row class="button-group">
     <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        :loading="xlsLoading"
        @click="downloadAll"
      >导出数据</el-button>
    </el-row> 
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column type="index" label="序号" :index="indexMethod" width="50"></el-table-column>
      <el-table-column
        prop="schoolName"
        label="学校"
        width="220"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="className"
        label="班级"
        width="180"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="personId"
        label="人员编号"
        width="110"
      ></el-table-column>
      <el-table-column
        prop="personName"
        label="人员姓名"
        width="130"
      ></el-table-column>
      <el-table-column
        prop="sumPoints"
        label="总获取积分"
        width="180"
      >
      <template slot-scope="{row}">
        {{row.pointsActive + row.pointsUsa}}
      </template>
      </el-table-column>
      <el-table-column
        prop="pointsActive"
        label="可兑换积分"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="pointsUsa"
        label="已兑换积分"
        width="180"
      ></el-table-column>
      <!-- <el-table-column
        prop="pointsAll"
        label="累计兑换积分"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="reflect"
        label="是否正在兑换"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="reflectPoints"
        label="被锁定的积分"
        width="180"
      ></el-table-column> -->
      <el-table-column
        prop="reflectMoney"
        label="累计兑换金额"
        width="180"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>
<script>
import Constant from "@/constant";
import SelectTree from "@/components/SelectTree";
import personPointsApi from "@/api/business/personPoints";
import companyInfoApi from "@/api/base/companyInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "BusinessPersonPointsList",
  data() {
    var self = this;

    return {
      queryModel: {
        companyId: "",
        personId: "",
        personName: "",
        subordinate:""
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      companyResult:[],
      xlsLoading:false
    };
  },
  created() {
    var self = this;
    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });
  },
  methods: {
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      if (self.queryModel.companyId == null) {
        self.queryModel.companyId = "";
      }
      formData.append("companyId", self.queryModel.companyId);
      formData.append("personId", self.queryModel.personId);
      formData.append("personName", self.queryModel.personName);
      formData.append("subordinate", self.queryModel.subordinate);


      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      personPointsApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    downloadAll() {
      //导出
      var self = this;
      var formData = new FormData();

      if (self.queryModel.companyId == null) {
        self.queryModel.companyId = "";
      }
      formData.append("companyId", self.queryModel.companyId);
      formData.append("personId", self.queryModel.personId);
      formData.append("personName", self.queryModel.personName);
      formData.append("subordinate", self.queryModel.subordinate);

      self.xlsLoading = true;

      personPointsApi.exportXls(formData).then(function (response) {
        self.xlsLoading = false;
        var jsonData = response.data;
        if (jsonData.result) {
          if (jsonData.data != null) {
            self.$message({
              showClose: true,
              type: "success",
              dangerouslyUseHTMLString: true,
              message: `报表已生成，<a href="${jsonData.data}" target="_blank">点击下载报表</a>&nbsp;`,
              duration: 30000,
            });
          }
        }
      });
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "el-select-tree": SelectTree
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>